import { createStore } from 'vuex';
import authModule from './modules/auth';

export default createStore({
  modules: {
    auth: authModule,
  },
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    isLoggedIn: (state) => {
      return state.auth.user !== null;
    },
  },
  plugins: [initializeStoreFromLocalStorage],
});

function initializeStoreFromLocalStorage(store) {
  const userData = localStorage.getItem('user');
  const token = localStorage.getItem('local_token');

  if (userData && token) {
    const user = JSON.parse(userData);
    console.log('User data loaded from local storage:', user);
    store.commit('auth/setUser', user);
    store.commit('auth/setToken', token);
  }
}