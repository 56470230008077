<template>
    <div class="modal fade" ref="loginModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered .modal-sm">
            <div class="modal-content login-popup-content">
                <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
                <!-- <div class="modal-header">
                <h5 class="modal-title" id="loginModalLabel">Modal title</h5>
                <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
                </div> -->
                <div class="modal-body">
                    <section class="login popup-login">
                        <div class="container-fluid">
                            <div class="login-card">
                                <h2>Hello Again !</h2>
                                <p>Please Sign in to continue with us</p>
                                <span class="text-danger mb-3" v-if="validations.length > 0">{{validations}}</span>
                                <div class="form-group">
                                    <input type="text" v-model="user_name" placeholder="Email" class="form-control">
                                    <span class="text-danger" v-if="validations.user_name">{{validations.user_name[0]}}</span>
                                </div>
                                <div class="form-group group_btn">
                                    <input type="password" v-model="password" placeholder="Passsword" class="form-control"> <span class="btn_pwd"><i class="fa fa-eye"></i></span>
                                    <span class="text-danger" v-if="validations.password">{{validations.password[0]}}</span>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <div class="chk-inner">
                                            <label class="check-holder">
                                                Remember Password
                                                <input type="checkbox" id="remember_pwd" name="remember_pwd" value="Yes">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col align-right"><a href="#">Forgot Password?</a></div>
                                </div>
                                <div class="form-group"><button class="btn btn-primary" @click="loginFormHandle" >Sign IN</button></div>
                                <div class="align-center">
                                    <span class="register-btn" style="cursor:pointer" @click="this.$parent.openSignup()"> Not a User? Please Register</span>
                                </div>
                                    
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
export default {
  
  data() {
      return {
        user_name: '',
        password: '',
        validations:[],
        apiUrl: process.env.VUE_APP_API_URL
      }
    },
    methods: {
         async loginFormHandle() {
             const _this = this
            try {
                await this.$store.dispatch('login', {
                  user_name: this.user_name,
                  password: this.password,
                });
                _this.modal.hide()
              } catch (error) {
                console.error('Login failed:', error);
              }
        },
    },
    mounted(){
        this.modal = new Modal(this.$refs.loginModal, {
            backdrop: 'static'
        });
      let user = localStorage.getItem('user')
      if(user){
        console.log(user);
      }
    },
    watch:{
        validations:function () {
      }
    }
}
</script>