<template>
    <section class="login">
      <div class="container-fluid">
          <img src="assets/images/logo-icon.png" alt="" class="login-logo">
        <!-- <div class="row">
          <div class="col-lg-6">
                  <img src="assets/images/logo-icon.png" alt="" class="login-logo">
              </div>
              <div class="col-lg-6">  
              </div>
        </div> -->
        <div class="text-box-login mt-5">
          <h1 class="font-sacramento">Secure best</h1>
          <h2>insurance plan & enjoy the life at every stage</h2>
          <h3>Banao Har Lamha Mehfooz</h3>
        </div>
        <div class="login-card">
              <span class="text-danger" v-if="finalValidationsFields.email">{{finalValidationsFields.email[0]}}</span>
              <div class="form-group">
                <input type="text" v-model="email" placeholder="Enter your email" class="form-control">
              </div>
              <div class="form-group">
                <button class="btn btn-primary" @click="forgotHandle" >Send Password</button>
              </div>
                
          </div>
      </div>
  </section>
</template>
<script>
import axios from 'axios'
import Swal from 'sweetalert2/dist/sweetalert2';
export default {
  
  data() {
      return {
        email: '',
        validations:[],
        finalValidationsFields: {},
        apiUrl: process.env.VUE_APP_API_URL
      }
    },
    methods: {
      showAlert() {
      // Use sweetalert2
      this.$swal('Hello Vue world!!!');
    },

         forgotHandle() {
             let _this=this;
            let config = {
                method: 'post',
                url: this.apiUrl+'forgotPassword',
                data: {
                    'email':this.email,
                },
            }

            axios(config)
            .then(function (response) {
                console.log(response)
                if(response.status==422){
                  // alert('dfsdf')
                  Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: response.data.msg
                  })
                }
                if(response.status==200){
                  // alert('dfsdf')
                  Swal.fire({
                      icon: 'success',
                      title: response.data.msg,
                  })
                  _this.email = ''
                }
                // if(response.status==200) {
                //     let user = response.data.data;
                //     localStorage.setItem('user', JSON.stringify(user));
                //     let LocalToken = response.data.data.token;
                //     localStorage.setItem('local_token', LocalToken);

                //     let OzoneToken = response.data.data.accessToken;
                //     localStorage.setItem('ozone_token', OzoneToken);
                //     _this.$router.push('/')
                //     return false;
                // }

            })
            .catch(function (e) {
              // console.log('e dddffdf')
              // console.log(e.response.data.response_code)
              if(e.response.data.response_code=== '404'){
                  // alert('dfsdf')
                  Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      text: e.response.data.message
                  })
                }
              // _this.validations=e.response.data.errors.errors;
              // _this.validations=(response.response.data.errors);
              // console.log(_this.validations)
              // var obj = {};
              //   for (var i = 0; i < _this.validations.length; i++) {
              //     obj[(_this.validations[i].field)] = Object.values([_this.validations[i].message])
              //   }
              //    _this.finalValidationsFields = obj
                // console.log("_this.finalValidationsFields")
                // console.log(_this.finalValidationsFields)
              //  if(response.response.status==404){
                  
              //  }
            });
        },
    },
    mounted(){
      
    },
    watch:{
        
    }
}
</script>