<template>
    <div>
        <section class="content-page">
            
            <div class="feature-form-main pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-12" v-if="serviceEndorsment">
                            <h2 v-show="myProducts"> My Plans </h2>
                            <div class="tab-content" v-if="myProducts">
                                <div class="pakages-main" style="margin: 0 -10px 0">



                                <div class="package-box" v-for="(value, index) in myProducts" :key="index" @click="handleEndorsement(value)">
                                    <!-- {{myProducts}} -->
                                    <div class="package-detail-box clearfix">
                                        <div class="cart-head">
                                            <span class="package-logo"><img :src="value.company_logo" /> </span>
                                            <strong class="title"> {{ value.product_name || 'N/A' }} </strong>
                                        </div>
                                        <div class="package-sum">
                                            <ul>
                                                <li>
                                                    <span class="sum-title"> Order ID </span>
                                                    <span class="sum-value"> {{ value.id }} </span>
                                                </li>
                                                <li>
                                                    <span class="sum-title"> Type </span>
                                                    <span class="sum-value"> {{ value.class_name }} </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <span class="package-grand"> <span class="premimum"> Premimum Amount </span> <strong> Rs. {{ parseFloat(value.premium_amount || 0).toLocaleString('en-US') }} </strong> </span>
                                        <button class="btn btn-primary"> Endorse </button>
                                    </div>
                                </div>
                                
                            </div>
                            </div>
                            <div v-else class="align-center">
                                <h1> Please Login to see your plans. </h1>
                            </div>
                        </div>
                        <div v-else>
                            <div class="col-12">
                                <div class="selected-package">
                                    <div class="package-detail-box clearfix">
                                        <div class="package-detail-col">
                                            <span class="package-logo"> <img src="https://application.inxurehub.com/image/company/1686381820.png" /> </span>
                                        </div>
                                        <div class="package-detail-col">
                                            <strong class="title"> {{selectedProduct?.company}} </strong>
                                            <span class="title-dot"> {{selectedProduct?.product_name}} </span>
                                        </div>
                                        <div class="package-detail-col total">
                                            <span class="total-head"> Total </span>
                                            <span class="total-head-grand"> Rs.  {{ parseFloat(selectedProduct?.product_price).toLocaleString('en-US') }} </span>
                                        </div>
                                        <div class="package-detail-col">
                                            <span class="stat-title"> Premium Amount </span>
                                            <span class="stat-number">  Rs.  {{ parseFloat(selectedProduct?.premium_amount).toLocaleString('en-US') }} </span>
                                        </div>
                                        <div class="package-detail-col">
                                            <span class="stat-title"> Order ID </span>
                                            <span class="stat-number">  {{selectedProduct?.id}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 mb-3">
                                    <label class="label block-label"> Name: </label>
                                    <input type="text" class="form-control" v-model="user.name" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label class="label block-label"> CNIC: </label>
                                    <input type="text" class="form-control"  v-model="user.cnic" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label class="label block-label"> Email: </label>
                                    <input type="text" class="form-control"  v-model="user.email" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label class="label block-label"> Mobile: </label>
                                    <input type="text" class="form-control"  v-model="user.mobile" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label class="label block-label"> Order ID: </label>
                                    <input type="text" :value="selectedProduct?.id" disabled class="form-control" />
                                </div>
                                <div class="col-md-6 mb-3">
                                    <label class="label block-label"> Adress: </label>
                                    <input type="text" class="form-control"  v-model="user.address" />
                                </div>
                                
                            </div>
                            <div class="col-12">
                                <div class="form-group align-right">
                                    <button @click="handleSubmit" class="btn btn-primary active"> Submit Endorsement </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
    export default {
        name: 'Endorsements',
        data(){
            return{
                user:{
                    name: '',
                    mobile: '',
                    email: '',
                    cnic: '',
                    address: ''
                },
                serviceEndorsment:true,
                apiUrl: process.env.VUE_APP_API_URL,
                myProducts: null,
                selectedProduct:[],
                remarks:'',
                userInfo: {}
            }
        },
        methods:{
            handleEndorsement(value){
                this.selectedProduct = value
                this.serviceEndorsment = false
            },

            handleMyproducts() {
                let _this=this;
                let localToken = "Bearer " + localStorage.getItem('local_token')
                let config = {
                    method: 'get',
                    url: _this.apiUrl+'my_products',
                    headers: {
                        'Authorization': `${localToken}`
                    },
                }
                axios(config)
                .then(function (response) {
                    if(response.status==200) {
                        _this.myProducts = response.data
                        
                    }

                })
                .catch(function (response) {
                })
                .finally(() => {
                }) 
            },

            handleSubmit(){
                let _this=this;
                let localToken = "Bearer " + localStorage.getItem('local_token')
                let config = {
                    method: 'post',
                    url: _this.apiUrl+'endrosement',
                    headers: {
                        'Authorization': `${localToken}`
                    },
                    data:{
                        order_id: _this.selectedProduct.id,
                        name: _this.user.name || '',
                        cnic: _this.user.cnic || '',
                        mobile: _this.user.mobile || '',
                        email: _this.user.email || '',
                        address: _this.user.address || '',

                    }
                }
                axios(config)
                .then(function (response) {
                    console.log('claims response')
                    console.log(response.status)
                    if(response.status === 200) {
                        _this.$swal.fire({
                            icon: 'success',
                            title: 'Congratulations',
                            text: response.data.message
                        })
                        _this.serviceEndorsment = !_this.serviceEndorsment
                    }

                })
                .catch(function (response) {
                })
                .finally(() => {
                })
            },
            
        },
        
        created() {
        },
        mounted(){
            this.user = {
                name : `${this.$store.state.auth.user.first_name} ${this.$store.state.auth.user.last_name}`,
                email: this.$store.state.auth.user.email,
                cnic: this.$store.state.auth.user.cnic,
                mobile: this.$store.state.auth.user.mobile,
                address: this.$store.state.auth.user.address,
            }
            this.handleMyproducts()
        },
        computed: {
            
        },
        watch: {
            
        }
    }
</script>