<template>
    <div class="myarea-header">
        <div class="container">
            <h1 class="myarea-heading"><img src="../assets/images/icons/arrow-shape-icon.png" alt=""> {{serviceName}} Insurance
            </h1>
        </div>
    </div>
    <section 
        class="myarea" 
        :class="serviceClass">
        <div class="myarea-inner">
            <div class="insurance-type">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="radio-inner">
                                <div class="gradient-border mb-lg-5 mb-4">
                                    <label class="radio-holder" @click="$router.push('/feature-form')">
                                        <img src="assets/images/icons/convention-ic.png" alt="">
                                        Conventional Insurance
                                        <input type="radio" name="Insurance" value="Conventional Insurance">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="gradient-border mb-lg-5 mb-4">
                                    <label class="radio-holder" @click="$router.push('/feature-form')">
                                        <img src="assets/images/icons/takaful-ic.png" alt="">
                                        Takaful
                                        <input type="radio" name="Insurance" value="Takaful">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <!-- <div class="align-right"><a href="#" class="btn btn-primary">Update <img
                                        src="assets/images/icons/arrow-up-right.png" alt=""></a></div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
    export default {
      components:{
      },
      data(){
        return{
            serviceName:""
        }
      },
      methods: {
        
      },
    created(){
        let name = localStorage.getItem('service')
        let vm = this
        if(name){
            vm.serviceName = JSON.parse(name).name
            console.log(vm.serviceName)
        }
      },
      computed:{
        serviceClass(){
            if(this.serviceName === 'Health' ){
                return 'health-insurance';
            }
            else if( this.serviceName === 'Motor'){
                return 'motor-insurance';
            }
            else if( this.serviceName === 'Motor'){
                return 'motor-insurance';
            }
            else if( this.serviceName === 'Travel'){
                return 'travel-insurance';
            }
            else if( this.serviceName === 'Home'){
                return 'home-insurance';
            }
        }
    }
}
</script>
  