<template>
    <div>
        <section class="content-page">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="profile-box">
                            <div class="user-head">
                                <span class="profile-avatar"></span>
                                <span class="user-name"> {{ user?.first_name }} {{ user?.last_name }} </span>
                                <span class="user-email"> {{ user?.email ?? 'N/A' }} </span>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <span class="label"> Mobile </span>
                                    <span class="value"> {{ user?.mobile ?? 'N/A' }} </span>
                                </div>
                                <div class="col-md-4">
                                    <span class="label"> Username </span>
                                    <span class="value"> {{ user?.user_name ?? 'N/A' }} </span>
                                </div>
                                <div class="col-md-4">
                                    <span class="label"> Country </span>
                                    <span class="value"> {{ user?.country ?? 'N/A' }} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
  <script>
    export default {
        name: 'Profile',
        data(){
            return{
                user:{},
            }
        },
        methods:{
            getUser(){
                let _this = this;
                let user = localStorage.getItem('user')
                
                
                if(user){
                    _this.user = JSON.parse(user)
                }
            },
            
        },
        
        created() {
            this.getUser();
        },
        mounted(){
            
        },
        computed: {
            
        },
    }
</script>
  