import axios from 'axios';

const state = {
    user: null,
    token: null,    
  };
  
  const mutations = {
    'auth/setUser'(state, user) {
        state.user = user;
      },
      'auth/setToken'(state, token) {
        state.token = token;
      },
      'auth/clearAuth'(state) {
        state.user = null;
        state.token = null;
      },
    
  };
  
  const actions = {
    async login({ commit }, { user_name, password }) {
      try {
        const response = await axios.post(process.env.VUE_APP_API_URL+'login', { user_name, password });
        if (response.status === 200 && response.data.data) {
          const userData = response.data.data;
          const token = response.data.data.token;
            console.log('login sssss')
            commit('auth/setUser', userData);
            commit('auth/setToken', token);
          localStorage.setItem('user', JSON.stringify(userData));
          localStorage.setItem('local_token', token);
          return 'Login successful';
        } else {
          throw new Error('Login failed');
        }
      } catch (error) {
        throw error;
      }
    },
    async logout({ commit }) {
        commit('auth/clearAuth');
        localStorage.removeItem('user');
        localStorage.removeItem('local_token');
    },
  };
  
  export default {
    state,
    mutations,
    actions,
  };
  