<template>
    <div class="modal fade" ref="loginModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content login-popup-content">
                <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
                <!-- <div class="modal-header">
                <h5 class="modal-title" id="loginModalLabel">Modal title</h5>
                <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
                </div> -->
                <div class="modal-body success-modal-body">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-6">
                                <div class="text-box">
                                    <img src="../../assets/images/success-icon.png" alt="" />
                                    <h1> Congratulations </h1>
                                    <p> Your Insurance Request has been processed and submitted successfully. Our representative will get back to you shortly. </p>
                                </div>
                            </div>
                            <div class="col-6">
                                <img src="../../assets/images/success-avatar.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
export default {
  
  data() {
      return {
        user_name: '',
        password: '',
        validations:[],
        apiUrl: process.env.VUE_APP_API_URL
      }
    },
    methods: {
         async loginFormHandle() {
             const _this = this
            try {
                await this.$store.dispatch('login', {
                  user_name: this.user_name,
                  password: this.password,
                });
                _this.modal.hide()
              } catch (error) {
                console.error('Login failed:', error);
              }
        },
    },
    mounted(){
        this.modal = new Modal(this.$refs.loginModal, {
            backdrop: 'static'
        });
      let user = localStorage.getItem('user')
      if(user){
        console.log(user);
      }
    },
    watch:{
        validations:function () {
      }
    }
}
</script>