<template>
    <div>
        <section class="content-page">
            <div class="feature-form-main pb-5">
                <div class="container">
                    <div class="row">
                        <div class="col-12" v-if="serviceClaim">
                            <h2 v-show="myProducts"> My Plans </h2>
                            <div class="tab-content" v-if="myProducts">
                                <div class="pakages-main" style="margin: 0 -10px 0">



                                <div class="package-box" v-for="(value, index) in myProducts" :key="index" @click="handleClaim(value)">
                                    <!-- {{myProducts}} -->
                                    <div class="package-detail-box clearfix">
                                        <div class="cart-head">
                                            <span class="package-logo"><img :src="value.company_logo" /> </span>
                                            <strong class="title"> {{ value.product_name || 'N/A' }} </strong>
                                        </div>
                                        <div class="package-sum">
                                            <ul>
                                                <li>
                                                    <span class="sum-title"> Order ID </span>
                                                    <span class="sum-value"> {{ value.id }} </span>
                                                </li>
                                                <li>
                                                    <span class="sum-title"> Type </span>
                                                    <span class="sum-value"> {{ value.class_name }} </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <span class="package-grand"> <span class="premimum"> Premimum Amount </span> <strong> Rs. {{ parseFloat(value.premium_amount || 0).toLocaleString('en-US') }} </strong> </span>
                                        <button class="btn btn-primary"> Claim </button>
                                    </div>
                                </div>
                                
                            </div>
                            </div>
                            <div v-else class="align-center">
                                <h1> Please Login to see your plans. </h1>
                            </div>
                        </div>
                        <div v-else>
                            <div class="col-12">
                                <div class="selected-package">
                                    <div class="package-detail-box clearfix">
                                        <div class="package-detail-col">
                                            <span class="package-logo"> <img src="https://application.inxurehub.com/image/company/1686381820.png" /> </span>
                                        </div>
                                        <div class="package-detail-col">
                                            <strong class="title"> {{selectedProduct?.company}} </strong>
                                            <span class="title-dot"> {{selectedProduct?.product_name}} </span>
                                        </div>
                                        <div class="package-detail-col total">
                                            <span class="total-head"> Total </span>
                                            <span class="total-head-grand"> Rs.  {{ parseFloat(selectedProduct?.product_price).toLocaleString('en-US') }} </span>
                                        </div>
                                        <div class="package-detail-col">
                                            <span class="stat-title"> Premium Amount </span>
                                            <span class="stat-number">  Rs.  {{ parseFloat(selectedProduct?.premium_amount).toLocaleString('en-US') }} </span>
                                        </div>
                                        <div class="package-detail-col">
                                            <span class="stat-title"> Order ID </span>
                                            <span class="stat-number">  {{selectedProduct?.id}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="label block-label"> Enter Feedback </label>
                                    <textarea v-model="remarks" class="form-control"></textarea>
                                    <span class="text-danger" v-if="validations.remarks">{{validations.remarks[0]}}</span>
                                </div>

                                <div class="form-group align-right">
                                    <button @click="handleSubmit" class="btn btn-primary active"> Submit Claim </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import axios from 'axios'
    export default {
        name: 'Claims',
        data(){
            return{
                user:{},
                serviceClaim: true,
                apiUrl: process.env.VUE_APP_API_URL,
                myProducts: null,
                selectedProduct:[],
                remarks:'',
                validations: []
            }
        },
        methods:{
            
            handleClaim(value){
                this.selectedProduct = value
                this.serviceClaim = false
            },
            handleSubmit(){
                let _this=this;
                let localToken = "Bearer " + localStorage.getItem('local_token')
                let config = {
                    method: 'post',
                    url: _this.apiUrl+'claim',
                    headers: {
                        'Authorization': `${localToken}`
                    },
                    data:{
                        order_id: _this.selectedProduct.id,
                        remarks: _this.remarks,
                    }
                }
                axios(config)
                .then(function (response) {
                    console.log('claims response')
                    console.log(response)
                    if(response.status==200) {
                        _this.$swal.fire({
                            icon: 'success',
                            title: 'Congratulations',
                            text: response.data.message
                        })
                        _this.serviceClaim = !_this.serviceClaim
                    }

                })
                .catch((e) => {
                    _this.validations=e.response.data.errors;
                    console.log(_this.validations)
                })
                .finally(() => {
                })
            },
            handleMyproducts() {
                let _this=this;
                let localToken = "Bearer " + localStorage.getItem('local_token')
                let config = {
                    method: 'get',
                    url: _this.apiUrl+'my_products',
                    headers: {
                        'Authorization': `${localToken}`
                    },
                }
                axios(config)
                .then(function (response) {
                    if(response.status==200) {
                        _this.myProducts = response.data
                        
                    }

                })
                .catch(function (response) {
                })
                .finally(() => {
                }) 
            },
            
        },
        
        created() {
        },
        mounted(){
            this.handleMyproducts()
        },
        computed: {
            
        },
    }
</script>
  