<template>
  <div>
      <div class="loading-callback">
          <fa-icon icon="circle-notch" spin />
      </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
    data(){
        return{
            apiUrl: process.env.VUE_APP_API_URL,
            validations : {}
        }
    },
    methods:{
      getUser(){
            let _this = this;
            let user = localStorage.getItem('user')
            
            
            if(user){
                _this.user = JSON.parse(user)
                console.log('_this.user')
                console.log(_this.user)
                _this.user_name = JSON.parse(user).first_name
                _this.loggedIn= true
            }
        },
    },
  mounted() {
    const clientId = "1041382476516-2idmj377g2ckkrh2ceji82sfdc6p5kvb.apps.googleusercontent.com";
    const clientSecret = "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";
    // const redirectUri = "https://stagging.inxurehub.com/callback";
    const redirectUri = "https://inxurehub.com/callback";
    // const redirectUri = "http://localhost:8080/callback";

    // Get the authorization code from the query parameters
    const code = this.$route.query.code;

    // Exchange the authorization code for an access token
    axios
      .post("https://oauth2.googleapis.com/token", {
        code,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri,
        grant_type: "authorization_code",
      })
      .then((response) => {
        const accessToken = response.data.access_token;

        // Use the access token to fetch the user's profile information
        axios
          .get("https://www.googleapis.com/oauth2/v1/userinfo", {
            headers: { Authorization: `Bearer ${accessToken}` },
          })
          .then((userInfoResponse) => {
              console.log(userInfoResponse)
              const email = userInfoResponse.data.email;
              const firstName = userInfoResponse.data.given_name;
              const lasttName = userInfoResponse.data.family_name;
              const social_key = userInfoResponse.data.id;
              // Perform any necessary actions with the user's email (e.g., signup, login, etc.)
            console.log("User signed up with Gmail:", email);
            let _this=this;
             let config = {
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                url:_this.apiUrl+'social_register',
                data: {
                  first_name: firstName,
                  // middle_name:this.middle_name,
                  last_name:lasttName,
                  social_key:social_key,
                //   username:_this.username,
                //   mobile_no:_this.mobile_no,
                  // cnic:this.cnic,
                //   password:_this.password,
                  email:email,
                  // profile_img:this.profile_img,
                },
            }

             axios(config)
             .then((response) => {
                  console.log(response)
                  if(response.status==200){
                    
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Congratulations',
                        text: response.data.message
                    })
                    let userData = response.data.data;
                    const token = response.data.data.token;
                    
                    this.$store.commit('auth/setUser', userData);
                    this.$store.commit('auth/setToken', token);

                    localStorage.setItem('user', JSON.stringify(userData));
                    localStorage.setItem('local_token', token);
                    _this.$router.push('/')
                    return false;
                  }
              })
              .catch((e) => {
                _this.validations=e.response.data.errors.errors;
                var obj = {};
                for (var i = 0; i < _this.validations.length; i++) {
                  obj[(_this.validations[i].field)] = Object.values([_this.validations[i].message])
                }
                 _this.finalValidationsFields = obj
                console.log(_this.finalValidationsFields)
                if(e.response.status==404){
                  
                }
              });
          })
          .catch((error) => {
            console.error("Error fetching user profile:", error);
          });
      })
      .catch((error) => {
        console.error("Error exchanging authorization code for access token:", error);
      });
  },
  created() {
        // this.intervalId = setInterval(() => {
        //     this.getUser();
        // }, 1000);
        setTimeout(() => {
            this.getUser();
        }, 2000);
    },
};
</script>