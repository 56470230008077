<template>
    <section class="login">
      <div class="container-fluid">
          <img src="assets/images/logo-icon.png" alt="" class="login-logo">
        <!-- <div class="row">
          <div class="col-lg-6">
                  <img src="assets/images/logo-icon.png" alt="" class="login-logo">
              </div>
              <div class="col-lg-6">  
              </div>
        </div> -->
        <div class="text-box-login">
          <h1 class="font-sacramento">Secure best</h1>
          <h2>insurance plan & enjoy the life at every stage</h2>
          <h3>Banao Har Lamha Mehfooz</h3>
        </div>
        <div class="login-card">
          <div class="loader" v-if="loader"></div>
              <h2>Hello Again !</h2>
              <p>Please Sign in to continue with us</p>
              <span class="text-danger mb-3" v-if="validations.length > 0">{{validations}}</span>
              <div class="form-group">
                <input type="text" v-model="user_name" placeholder="Email" class="form-control">
                <span class="text-danger" v-if="validations.user_name">{{validations.user_name[0]}}</span>
              </div>
              <div class="form-group group_btn">
                <input :type="passwordType" v-model="password" placeholder="Passsword" class="form-control"> <span class="btn_pwd" @click="viewPassword"><i class="fa fa-eye"></i></span>
                <span class="text-danger" v-if="validations.password">{{validations.password[0]}}</span>
              </div>
              <div class="row mb-3">
                  <div class="col">
                      <div class="chk-inner">
                          <label class="check-holder">
                              Remember Password
                              <input type="checkbox" id="remember_pwd" name="remember_pwd" value="Yes">
                              <span class="checkmark"></span>
                          </label>
                      </div>
                  </div>
                  <div class="col align-right">
                    <router-link to="/forgot-password">Forgot Password?</router-link>
                  </div>
              </div>
              <div class="form-group"><button class="btn btn-primary" @click="loginFormHandle()" >Sign IN</button></div>
              <div class="align-center">
                <router-link class="register-btn" to="/signup"> Not a User? Please Register </router-link>
              </div>
                
          </div>
      </div>
  </section>
</template>
<script>
import axios from 'axios'
export default {
  
  data() {
      return {
        user_name: '',
        password: '',
        validations:[],
        passwordType: 'password',
        apiUrl: process.env.VUE_APP_API_URL,
        loader:false
      }
    },
    methods: {
        viewPassword(){
          this.passwordType = this.passwordType === "password" ? "text" : "password";
        },
         async loginFormHandle() {
           this.loader = true
           try {
                await this.$store.dispatch('login', {
                  user_name: this.user_name,
                  password: this.password,
                });

                this.$router.push('/');
              } catch (error) {
                // console.error('Login failed:', error);
                this.validations = error.response.data.errors
              } finally {
                this.loader = false;
              }
            
        },
        
    },
    mounted(){
      // let user = localStorage.getItem('user')
      // if(user){
      //   console.log(user);
      //   this.$router.push('/')
      // }
    },
    created() {
        // this.intervalId = setInterval(() => {
        //     this.getUser();
        // }, 1000);
        // setTimeout(() => {
        //     this.getUser();
        // }, 2000);
    },
    watch:{
        validations:function () {
      }
    }
}
</script>