<template>
    <section class="login">
      <div class="container-fluid">
          <img src="assets/images/logo-icon.png" alt="" class="login-logo">
        <!-- <div class="row">
          <div class="col-lg-6">
                  <img src="assets/images/logo-icon.png" alt="" class="login-logo">
              </div>
              <div class="col-lg-6">  
              </div>
        </div> -->
        <div class="text-box-login">
          <h1 class="font-sacramento">Secure best</h1>
          <h2>insurance plan & enjoy the life at every stage</h2>
          <h3>Banao Har Lamha Mehfooz</h3>
        </div>
        <div class="login-card">
          <div class="loader" v-if="loader"></div>
              <h2>Hello Again!</h2>
              <p>Please Sign up to continue with us</p>
              <div class="form-group">
                <input type="text" v-model="first_name" placeholder="First Name" class="form-control">
                <span class="text-danger" v-if="finalValidationsFields.first_name"
                >
                  {{finalValidationsFields.first_name[0]}}
                </span>
              </div>
              <!-- <div class="form-group">
                <input type="text" v-model="middle_name" placeholder="Middle Name" class="form-control">
                <span class="text-danger" v-if="validations.middle_name">{{validations.middle_name[0]}}</span>
              </div> -->
              <div class="form-group">
                  <input type="text" v-model="last_name" placeholder="Last Name" class="form-control">
                  <span class="text-danger" v-if="finalValidationsFields.last_name"
                >
                  {{finalValidationsFields.last_name[0]}}
                </span>
              </div>
              <div class="form-group">
                  <input type="text" v-model="mobile_no" placeholder="Mobile No," class="form-control">
                  <span class="text-danger" v-if="finalValidationsFields.mobile_no"
                >
                  {{finalValidationsFields.mobile_no[0]}}
                </span> 
              </div>
              <!-- <div class="form-group">
                  <input type="text" v-model="cnic" placeholder="CNIC" class="form-control">
                  <span class="text-danger" v-if="validations.cnic">{{validations.cnic[0]}}</span>  
              </div> -->
              
              <div class="form-group">
                <input type="email" v-model="email" placeholder="Email" class="form-control">
                <span class="text-danger" v-if="finalValidationsFields.email"
                >
                  {{finalValidationsFields.email[0]}}
                </span> 
              </div>

              <div class="form-group">
                <input type="text" v-model="username" placeholder="User Name" class="form-control">
                <span class="text-danger" v-if="finalValidationsFields.username"
                >
                  {{finalValidationsFields.username[0]}}
                </span> 
              </div>

              <div class="form-group">
                  <input type="text" v-model="password" placeholder="Password" class="form-control">
                  <span class="text-danger" v-if="finalValidationsFields.password"
                >
                  {{finalValidationsFields.password[0]}}
                </span> 
              </div>

              <!-- <div class="form-group">
                <input type="file" @change="getProfileImg" value="" />
                <span class="text-danger" v-if="validations.avatar">{{validations.avatar[0]}}</span> 
                <img :src="profile_img" />
              </div> -->
              
              <div class="row">
                <div class="col-md-6">
                    <div class="form-group"><button class="btn btn-primary w-100" @click="signupForm" >Sign Up</button></div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                      <button 
                        class="btn btn-primary google-signup w-100" 
                        @click="handleGmailSignup">
                        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M32 16.3637C32 15.2292 31.8961 14.1383 31.7032 13.091H16.3265V19.2801H25.1132C24.7347 21.2801 23.5844 22.9746 21.8553 24.1092V28.1237H27.1317C30.2189 25.3382 32 21.2364 32 16.3637Z" fill="#4285F4"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3265 31.9999C20.7347 31.9999 24.4304 30.5673 27.1317 28.1237L21.8553 24.1092C20.3933 25.0692 18.5232 25.6363 16.3265 25.6363C12.0742 25.6363 8.47497 22.8218 7.19111 19.04H1.73656V23.1854C4.42302 28.4145 9.94436 31.9999 16.3265 31.9999Z" fill="#34A853"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M7.19111 19.04C6.86458 18.08 6.67904 17.0546 6.67904 16.0001C6.67904 14.9455 6.86457 13.9201 7.1911 12.9601V8.81462H1.73655C0.630798 10.9746 0 13.4182 0 16.0001C0 18.5819 0.630805 21.0254 1.73656 23.1854L7.19111 19.04Z" fill="#FBBC05"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3265 6.36363C18.7236 6.36363 20.8757 7.1709 22.5677 8.75635L27.2505 4.16727C24.423 1.58545 20.7273 0 16.3265 0C9.94436 0 4.42301 3.58553 1.73655 8.81462L7.1911 12.9601C8.47496 9.17825 12.0742 6.36363 16.3265 6.36363Z" fill="#EA4335"/>
                        </svg>

                          Signup with Google
                          
                      </button>
                    </div>
                </div>
              </div>
          </div>
      </div>
  </section>
</template>
<script>
    import axios from 'axios'


export default {
  name: 'Signup',
  components: {
   
  },
  data() {
      return {
        first_name: '',
        // middle_name: '',
        last_name: '',
        mobile_no: '',
        // cnic: '',
        password: '',
        email: '',
        // profile_img: '',
        username: '',
        validations:{},
        finalValidationsFields: {},
        apiUrl: process.env.VUE_APP_API_URL,
        loader: false
      }
    },
    methods: {
      handleGmailSignup() {
          const clientId = "1041382476516-2idmj377g2ckkrh2ceji82sfdc6p5kvb.apps.googleusercontent.com";
          // const redirectUri = "https://stagging.inxurehub.com/callback"; // e.g., http://localhost:8080/callback
          const redirectUri = "https://inxurehub.com/callback"; // e.g., http://localhost:8080/callback
          // const redirectUri = "http://localhost:8080/callback"; // e.g., http://localhost:8080/callback

          const authUrl =
            "https://accounts.google.com/o/oauth2/auth" +
            `?client_id=${clientId}` +
            "&response_type=code" +
            "&scope=email%20profile" +
            `&redirect_uri=${encodeURIComponent(redirectUri)}`;

          // Redirect the user to the Google authentication page
          window.location.href = authUrl;
        },
        resetValidationsMessage:function(){
          this.validations={};
        },
        getProfileImg(event) {
            this.profile_img = event.target.files[0]
        },
         async signupForm() {
             let _this=this;
             this.resetValidationsMessage();
             this.loader = true
             let config = {
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                url:_this.apiUrl+'register',
                data: {
                  first_name:_this.first_name,
                  // middle_name:this.middle_name,
                  last_name:_this.last_name,
                  username:_this.username,
                  mobile_no:_this.mobile_no,
                  // cnic:this.cnic,
                  password:_this.password,
                  email:_this.email,
                  // profile_img:this.profile_img,
                },
            }

             axios(config)
             .then((response) => {
                  // console.log(response)
                  if(response.status==200){
                    
                    this.$swal.fire({
                        icon: 'success',
                        title: 'Congratulations',
                        text: response.data.message
                    })
                    _this.first_name="",
                    // _this.middle_name="",
                    _this.last_name="",
                    _this.mobile_no="",
                    // _this.cnic="",
                    _this.password="",
                    _this.email="",
                    _this.country_code="",
                    _this.avatar="",
                    _this.username=""
                    this.$router.push('/login')
                  }
              })
              .catch((e) => {
                _this.validations=e.response.data.errors.errors;
                var obj = {};
                for (var i = 0; i < _this.validations.length; i++) {
                  obj[(_this.validations[i].field)] = Object.values([_this.validations[i].message])
                }
                 _this.finalValidationsFields = obj
                console.log(_this.finalValidationsFields)
                if(e.response.status==404){
                  
                }
              }).finally(()=>{
                this.loader = false
              });
        },
    },
    mounted(){
      let user = localStorage.getItem('user')
      if(user){
        console.log(user);
        this.$router.push('/')
      }
    },
    watch:{
      validations:function(){},
    }
}
</script>