<template>
    <div class="modal fade" ref="signupModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered .modal-sm">
            <div class="modal-content login-popup-content">
                <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
                <!-- <div class="modal-header">
                <h5 class="modal-title" id="loginModalLabel">Modal title</h5>
                <button type="button" class="btn-close" @click="modal.hide()" aria-label="Close"></button>
                </div> -->
                <div class="modal-body">
                    <section class="login popup-login">
                        <div class="container-fluid">
                            <div class="login-card">
                                <h2>Hello Again !</h2>
                                <p>Please Sign up to continue with us</p>
                                <div class="form-group">
                                    <input type="text" v-model="first_name" placeholder="First Name" class="form-control">
                                    <span class="text-danger" v-if="finalValidationsFields.first_name"
                                    >
                                    {{finalValidationsFields.first_name[0]}}
                                    </span>
                                </div>
                                <!-- <div class="form-group gradient-border">
                                    <input type="text" v-model="middle_name" placeholder="Middle Name" class="form-control">
                                    <span class="text-danger" v-if="validations.middle_name">{{validations.middle_name[0]}}</span>
                                </div> -->
                                <div class="form-group">
                                    <input type="text" v-model="last_name" placeholder="Last Name" class="form-control">
                                    <span class="text-danger" v-if="finalValidationsFields.last_name"
                                    >
                                    {{finalValidationsFields.last_name[0]}}
                                    </span>
                                </div>
                                <div class="form-group">
                                    <input type="text" v-model="mobile_no" placeholder="Mobile No," class="form-control">
                                    <span class="text-danger" v-if="finalValidationsFields.mobile_no"
                                    >
                                    {{finalValidationsFields.mobile_no[0]}}
                                    </span> 
                                </div>
                                <!-- <div class="form-group gradient-border">
                                    <input type="text" v-model="cnic" placeholder="CNIC" class="form-control">
                                    <span class="text-danger" v-if="validations.cnic">{{validations.cnic[0]}}</span>  
                                </div> -->
                                
                                <div class="form-group">
                                    <input type="email" v-model="email" placeholder="Email" class="form-control">
                                    <span class="text-danger" v-if="finalValidationsFields.email"
                                    >
                                    {{finalValidationsFields.email[0]}}
                                    </span> 
                                </div>

                                <div class="form-group">
                                    <input type="text" v-model="username" placeholder="User Name" class="form-control">
                                    <span class="text-danger" v-if="finalValidationsFields.username"
                                    >
                                    {{finalValidationsFields.username[0]}}
                                    </span> 
                                </div>

                                <div class="form-group">
                                    <input type="text" v-model="password" placeholder="Password" class="form-control">
                                    <span class="text-danger" v-if="finalValidationsFields.password"
                                    >
                                    {{finalValidationsFields.password[0]}}
                                    </span> 
                                </div>

                                <!-- <div class="form-group gradient-border">
                                    <input type="file" @change="getProfileImg" value="" />
                                    <span class="text-danger" v-if="validations.avatar">{{validations.avatar[0]}}</span> 
                                    <img :src="profile_img" />
                                </div> -->
                                
                                <div class="form-group"><button class="btn btn-primary" @click="signupForm" >Sign Up</button></div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import { Modal } from 'bootstrap'
export default {
    name: 'SignupModal',
    data() {
        return {
            first_name: '',
            // middle_name: '',
            last_name: '',
            mobile_no: '',
            // cnic: '',
            password: '',
            email: '',
            // profile_img: '',
            username: '',
            validations:{},
            finalValidationsFields: {},
            apiUrl: process.env.VUE_APP_API_URL
        }
    },
    methods: {
        resetValidationsMessage:function(){
          this.validations={};
        },
        getProfileImg(event) {
            this.profile_img = event.target.files[0]
        },
         async signupForm() {
             let _this=this;
             this.resetValidationsMessage();
             let config = {
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                url: _this.apiUrl+'register',
                data: {
                  first_name:_this.first_name,
                  last_name:_this.last_name,
                  username:_this.username,
                  mobile_no:_this.mobile_no,
                  password:_this.password,
                  email:_this.email,
                },
            }

             axios(config)
             .then((response) => {
                  if(response.status==200){
                    _this.first_name="",
                    _this.last_name="",
                    _this.mobile_no="",
                    _this.password="",
                    _this.email="",
                    _this.username,
                    _this.avatar="",
                    _this.modal.hide()
                    this.$parent.$refs.LoginModal.modal.show()
                  }
              })
              .catch((e) => {
                _this.validations=e.response.data.errors.errors;
                var obj = {};
                for (var i = 0; i < _this.validations.length; i++) {
                  obj[(_this.validations[i].field)] = Object.values([_this.validations[i].message])
                }
                 _this.finalValidationsFields = obj
                console.log(_this.finalValidationsFields)
                if(e.response.status==404){
                  
                }
              });
        },
    },
    mounted(){
        this.modal = new Modal(this.$refs.signupModal)
        let user = localStorage.getItem('user')
        if(user){
            console.log(user);
        }
    },
    watch:{
        validations:function () {
      }
    }
}
</script>