import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Feature from '../views/Feature.vue'
import FeatureForm from '../views/FeatureForm.vue'
import Contact from '../views/Contact.vue'
import Login from '../views/Login.vue'
import Signup from '../views/Signup.vue'
import Faq from '../views/Faq.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import TermOfUser from '../views/TermOfUser'
import ComingSoon from '../views/ComingSoon'
import Success from '../views/Success'
import Error from '../views/Error'
import ForgotPassword from '../views/ForgotPassword'
import Waitlist from '../views/WaitList'
import Callback from "../views/Callback.vue";
import Profile from "../views/Profile.vue";
import Claims from "../views/Claims.vue";
import Endorsements from "../views/Endorsements.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'InXurehub - Find The Best Insurance Companies in Pakistan',
      description: `InXurehub: Your Premier Online Marketplace to Discover the Best Insurance Companies in Pakistan that fits your needs and budget. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Companiess, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/',
      alternate: 'https://www.inxurehub.com/',
    },
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: Waitlist
  // },
  {
    path: '/callback',
    name: 'callback',
    component: Callback,
    meta: {
      title: 'Get Insured, Banao Har Lmaha Mehfooz',
      description: `Protect every moments with InxureHub - Pakistan's best insurance company. Comprehensive coverage, personalized plans. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/callback',
      alternate: 'https://www.inxurehub.com/callback'
    },
  },
  {
    path: '/feature',
    name: 'feature',
    component: Feature
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact,
    meta: {
      title: 'Get Insured, Banao Har Lmaha Mehfooz',
      description: `Protect every moments with InxureHub - Pakistan's best insurance company. Comprehensive coverage, personalized plans. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/contact',
      alternate: 'https://www.inxurehub.com/contact'
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Get Insured, Banao Har Lmaha Mehfooz',
      description: `Protect every moments with InxureHub - Pakistan's best insurance company. Comprehensive coverage, personalized plans. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/login',
      alternate: 'https://www.inxurehub.com/login'
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup,
    meta: {
      title: 'Get Insured, Banao Har Lmaha Mehfooz',
      description: `Protect every moments with InxureHub - Pakistan's best insurance company. Comprehensive coverage, personalized plans. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/signup',
      alternate: 'https://www.inxurehub.com/signup'
    },
  },
  {
    path: '/:alias',
    name: 'feature-form',
    component: FeatureForm,
    props: true,
    canonical: 'https://www.inxurehub.com/:alias',
    alternate: 'https://www.inxurehub.com/:alias',
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    meta: {
      title: 'Get Insured, Banao Har Lmaha Mehfooz',
      description: `Protect every moments with InxureHub - Pakistan's best insurance company. Comprehensive coverage, personalized plans. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/faq',
      alternate: 'https://www.inxurehub.com/faq',
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
    meta: {
      title: 'Get Insured, Banao Har Lmaha Mehfooz',
      description: `Protect every moments with InxureHub - Pakistan's best insurance company. Comprehensive coverage, personalized plans. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/privacy-policy',
      alternate: 'https://www.inxurehub.com/privacy-policy',
    },
  },
  {
    path: '/term-of-user',
    name: 'term-of-user',
    component: TermOfUser,
    meta: {
      title: 'Get Insured, Banao Har Lmaha Mehfooz',
      description: `Protect every moments with InxureHub - Pakistan's best insurance company. Comprehensive coverage, personalized plans. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/term-of-user',
      alternate: 'https://www.inxurehub.com/term-of-user',
    },
  },
  {
    path: '/coming-soon',
    name: 'coming-soon',
    component: ComingSoon,
    meta: {
      title: 'Get Insured, Banao Har Lmaha Mehfooz',
      description: `Protect every moments with InxureHub - Pakistan's best insurance company. Comprehensive coverage, personalized plans. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/coming-soon',
      alternate: 'https://www.inxurehub.com/coming-soon',
    },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta: {
      title: 'Get Insured, Banao Har Lmaha Mehfooz',
      description: `Protect every moments with InxureHub - Pakistan's best insurance company. Comprehensive coverage, personalized plans. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/forgot-password',
      alternate: 'https://www.inxurehub.com/forgot-password',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      title: 'Get Insured, Banao Har Lmaha Mehfooz',
      description: `Protect every moments with InxureHub - Pakistan's best insurance company. Comprehensive coverage, personalized plans. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/profile',
      alternate: 'https://www.inxurehub.com/profile'
    },
  },
  {
    path: '/claims',
    name: 'claims',
    component: Claims,
    meta: {
      title: 'Get Insured, Banao Har Lmaha Mehfooz',
      description: `Protect every moments with InxureHub - Pakistan's best insurance company. Comprehensive coverage, personalized plans. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/claims',
      alternate: 'https://www.inxurehub.com/claims'
    },
  },
  {
    path: '/endorsements',
    name: 'endorsements',
    component: Endorsements,
    meta: {
      title: 'Get Insured, Banao Har Lmaha Mehfooz',
      description: `Protect every moments with InxureHub - Pakistan's best insurance company. Comprehensive coverage, personalized plans. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/endorsements',
      alternate: 'https://www.inxurehub.com/endorsements'
    },
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    meta: {
      title: 'Get Insured, Banao Har Lmaha Mehfooz',
      description: `Protect every moments with InxureHub - Pakistan's best insurance company. Comprehensive coverage, personalized plans. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/about',
      alternate: 'https://www.inxurehub.com/about'
    },
  },
  {
    path: '/success',
    name: 'success',
    component: Success,
    meta: {
      title: 'Get Insured, Banao Har Lmaha Mehfooz',
      description: `Protect every moments with InxureHub - Pakistan's best insurance company. Comprehensive coverage, personalized plans. Banao Har Lamha Mehfooz.`,
      keywords: 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub',
      canonical: 'https://www.inxurehub.com/success',
      alternate: 'https://www.inxurehub.com/success'
    },
  },
  {
    path: '/error',
    name: 'error',
    component: Error
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // scrollBehavior: (to, from, savedPosition) => {
  //   if (savedPosition) {
  //     return savedPosition;
  //   } else if (to.hash) {
  //     return {
  //       selector: to.hash
  //     };
  //   } else {
  //     return { x: 0, y: 0 };
  //   }
  // }
})

router.beforeEach((to, from, next) => {
  if (to.path !== '/') {
    document.title = 'Get Insured, Banao Har Lmaha Mehfooz';
  }else{
    document.title = 'InXurehub - Find The Best Insurance Companies in Pakistan'
  }
  // // Update the title and meta description for the current route
  // document.title = to.meta.title || 'Get Insured, Banao Har Lmaha Mehfooz - Best Insurance Company in Pakistan';

  // // Check if the description meta tag exists
  // const descriptionMeta = document.querySelector('meta[name="description"]');

  // if (descriptionMeta) {
  //   // Update the content of the existing meta tag
  //   descriptionMeta.content = to.meta.description || 'IXBInxureHub - Banao Har Lamha Mehfooz - Best Insurance Company in Pakistan';
  // } else {
  //   // Create a new meta tag if it doesn't exist
  //   const newDescriptionMeta = document.createElement('meta');
  //   newDescriptionMeta.name = 'description';
  //   newDescriptionMeta.content = to.meta.description || 'IXBInxureHub - Banao Har Lamha Mehfooz - Best Insurance Company in Pakistan';
  //   document.head.appendChild(newDescriptionMeta);
  // }

  // // Check if the keywords meta tag exists
  // const keywordsMeta = document.querySelector('meta[name="keywords"]');

  // if (keywordsMeta) {
  //   // Update the content of the existing meta tag
  //   keywordsMeta.content = to.meta.keywords || 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub';
  // } else {
  //   // Create a new meta tag if it doesn't exist
  //   const newKeywordsMeta = document.createElement('meta');
  //   newKeywordsMeta.name = 'keywords';
  //   newKeywordsMeta.content = to.meta.keywords || 'Best Insurance Company, Best Insurance Company in Lahore, Top Insurance Companies in Pakistan, InxureHub';
  //   document.head.appendChild(newKeywordsMeta);
  // }

  // const canonicalLink = document.querySelector('link[rel="canonical"]');

  // if (canonicalLink) {
  //   canonicalLink.href = to.meta.canonical || 'https://www.inxurehub.com/';
  // } else {
  //   // Create a new canonical link tag if it doesn't exist
  //   const newCanonicalLink = document.createElement('link');
  //   newCanonicalLink.rel = 'canonical';
  //   newCanonicalLink.href = to.meta.canonical || 'https://www.inxurehub.com/';
  //   document.head.appendChild(newCanonicalLink);
  // }

  // const alternateLink = document.querySelector('link[rel="alternate"]');

  // if (alternateLink) {
  //   alternateLink.href = to.meta.alternate || 'https://www.inxurehub.com/';
  // } else {
  //   // Create a new canonical link tag if it doesn't exist
  //   const newAlternateLinkLink = document.createElement('link');
  //   newAlternateLinkLink.rel = 'canonical';
  //   newAlternateLinkLink.href = to.meta.alternate || 'https://www.inxurehub.com/';
  //   document.head.appendChild(newAlternateLinkLink);
  // }

  // if (alternateLink) {
  //   alternateLink.href = to.meta.canonical || getDefaultCanonicalUrl(to);
  // } else {
  //   // Create a new canonical link tag if it doesn't exist
  //   const newAlternateLinkLink = document.createElement('link');
  //   newAlternateLinkLink.rel = 'canonical';
  //   newAlternateLinkLink.href = to.meta.canonical || getDefaultCanonicalUrl(to);
  //   document.head.appendChild(newAlternateLinkLink);
  // }

  // if (canonicalLink) {
  //   canonicalLink.href = to.meta.canonical || getDefaultCanonicalUrl(to);
  // } else {
  //   // Create a new canonical link tag if it doesn't exist
  //   const newCanonicalLink = document.createElement('link');
  //   newCanonicalLink.rel = 'canonical';
  //   newCanonicalLink.href = to.meta.canonical || getDefaultCanonicalUrl(to);
  //   document.head.appendChild(newCanonicalLink);
  // }

  // Scroll to the top of the page
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  next();
});
// function getDefaultCanonicalUrl(route) {
//   // Use the route information to dynamically generate the canonical URL
//   return `https://www.inxurehub.com${route.path}`;
// }

export default router