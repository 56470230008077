<template>
  <div>
    <div v-if="loader" id="preloader" class="loader" >
      <div class="spinner">
      </div>
    </div>
    <a class="whatsapp-cta" href="https://api.whatsapp.com/send?phone=+923366999991&text=Hi,%20I%20want%20to%20inquire%20about%20%20https://inxurehub.com" target="_blank"> <fa-icon :icon="['fab', 'whatsapp']" /> </a>
    <Header :showHeader="showHeader" />
    <router-view :key="$route.fullPath"></router-view>
    <Footer :showFooter="showFooter" />
  </div>
</template>

<script>
    import Header from '@/components/layouts/Header.vue'
    import Footer from '@/components/layouts/Footer.vue'
    export default {
      components:{
        Header,
        Footer,
      },
      watch: {
        // $route: {
        //     immediate: true,
        //     handler(to, from) {
        //         document.title = to.meta.title || 'IXBInxureHub - Banao Har Lamha Mehfooz - Best Insurance Company in Pakistan';
        //     }
        // },
    },
    created(){
        // this.loader=false
        
        const initialURL = window.location.pathname;

        // // Extract the alias from the URL
        const segments = initialURL.split('/');
        const alias = segments[segments.length - 1];
        // alert(alias)
        // Check if the alias matches any known aliases and navigate to the route
        if (alias) {
          this.$router.push(`/${alias}`);
        }
        //  else if (alias === 'health-Insurance') {
        //   this.$router.push('/health-Insurance');
        // }else if (alias === 'personal-accidental-insurance') {
        //   this.$router.push('/personal-accidental-insurance');
        // }else if (alias === 'travel-insurance') {
        //   this.$router.push('/travel-insurance');
        // }
      },
      computed: {
        showHeader() {
          return this.$route.path !== '/wait-list';
          // return this.$route.path !== '/wait-list' && this.$route.path !== '/';
        },
        showFooter() {
          return this.$route.path !== '/wait-list';
          // return this.$route.path !== '/wait-list' && this.$route.path !== '/';
        },
      },
    }
</script>

